.modal-global {
    .modal-header {
        border-bottom: none;

        .modal-title {
            font-size: 15px;
        }
    }

    .modal-body {
        margin: 0;
        padding: 0 16px;

        .form-label {
            font-size: 13px;

            span {
                color: #DC492B;
            }
        }

        .checked-rows {
            gap: 1rem;
            display: flex;
        }

        .checked-rows-flex {
            gap: 5px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .upload-file {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }

        .upload-label {
            font-size: 7px;
            color: #2B2B2B;
            margin: 0 0 5px;
        }

        .upload-container {
            display: flex;
            border-radius: 5px;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            background-color: #01B762;

            .form-check-label {
                gap: 5px;
                color: #fff;
                padding: 20px;
                display: flex;
                align-items: center;
                flex-direction: column;

                i {
                    &::before {
                        font-size: 25px;
                    }
                }
            }
        }

        .labels {
            display: flex;
            margin-bottom: 0.5rem;
            flex-direction: column;

            label {
                margin: 0;
            }
        }

        .select-table {
            padding: 0;
            border: none;
            display: inline;
            border-radius: 0;
            background: none;
            color: #313D85;
            font-weight: 500;
            border-bottom: solid 1px #313D85;
        }

        .list-table {
            margin: 0 0 1rem;
            padding: 0;
            list-style: none;
            max-height: 154px;
            border-radius: 5px;
            overflow-y: scroll;
            border: 1px solid #DBDBDB;

            li {
                font-size: 13px;
                color: #2B2B2B;
                padding: 5px 10px;
                border-bottom: 1px solid #DBDBDB;

                &:last-child {
                    border-bottom: none;
                }
            }

            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            &::-webkit-scrollbar-thumb {
                background: #8A8E91;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        }

        .form-group {}

        .form-upload {
            .form-label {
                font-size: 11px;

                span {
                    display: block;
                    font-size: 7px;
                    color: #2B2B2B;
                }
            }

            .image-upload-container {
                gap: 1rem;
                display: flex;
                align-items: center;
                // justify-content: start;
                justify-content: flex-start;

                img {
                    width: 10rem;
                    height: 7rem;
                    border-radius: 5px;
                    object-fit: cover;
                }
            }
        }
    }

    .modal-footer {
        justify-content: flex-start;

        .btn.btn-primary {
            color: #fff;
            background-color: #313D85;
        }

        .btn.btn-secondary {
            color: #313D85;
            background-color: #fff;
            border: 1px solid #313D85;
        }

        &.centered {
            justify-content: center;
        }
    }
}