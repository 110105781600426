$primaryColor: #313D85;

.bg-primary-pg {
  background-color: $primaryColor;
}

.text-primary-pg {
  color: $primaryColor;
}

.border-primary-pg {
  border-color: $primaryColor !important;
}